import qs from 'qs';
import getFiltersQuery from '@src/containers/Filters/selectors/getFiltersQuery';
import getFavourites from '@src/containers/Session/selectors/getFavourites';
import mapServerSpace from '@src/libs/utils/mapServerSpace';
import mapRatingAndImgs from '@src/libs/utils/mapSpaceCard';
import { createSelector } from 'reselect';
import { serverSpaceItems } from '.';

const getMappedSpaces = createSelector(
	serverSpaceItems,
	(s) => s.Session.cityFromUrl?.id,
	(s) => s.Session.params?.town,
	getFavourites,
	getFiltersQuery,
	(serverItems, _cityId, town, favs, filters) => {
		const favIds = favs?.spaces.map(({ id }) => id) || [];
		const first_id = +filters.first_id;
		const { spec, capacity } = filters;
		const profileQuery = qs.stringify(
			{ spec, capacity },
			{ arrayFormat: 'comma' },
		);
		const useQueryInLinks = false;
		const q =
			useQueryInLinks && profileQuery.length ? `?${profileQuery}` : '';

		const mapper = mapServerSpace({
			favIds,
			town,
			first_id,
			additional: (item, additional) => {
				return mapRatingAndImgs(
					{ ...item, linkTo: `${item.linkTo}${q}` },
					additional,
				);
			},
		});
		return serverItems.map(mapper);
	},
);

export default getMappedSpaces;
